<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    @before-open="beforeOpen"
    :name="modalName"
  >
    <div class="modal--wrapper">
      <div class="modal--form-container">
        <form @submit.prevent="submit">
          <div class="form--group row" :class="{ 'has-error': validation.hasError('name') }">
            <label class="col-12 col-lg-4">{{ $t('adminUser.name') }}</label>
            <div class="col-12 col-lg-8">
              <v-text-field type="text" outlined hide-details v-model="name" />
              <span class="val-error">{{ validation.firstError('name') }}</span>
            </div>
          </div>
          <div class="form--group row" :class="{ 'has-error': validation.hasError('email') }">
            <label class="col-12 col-lg-4">{{ $t('adminUser.email') }}</label>
            <div class="col-12 col-lg-8">
              <v-text-field type="text" outlined hide-details v-model="email" />
              <span class="val-error">{{ validation.firstError('email') }}</span>
            </div>
          </div>
          <div class="form--group row" :class="{ 'has-error': validation.hasError('password') }">
            <label class="col-12 col-lg-4">{{ $t('adminUser.password') }}</label>
            <div class="col-12 col-lg-8">
              <v-text-field type="text" outlined hide-details v-model="password" />
              <span class="val-error">{{ validation.firstError('password') }}</span>
            </div>
          </div>
          <div class="form--group row" :class="{ 'has-error': validation.hasError('group') }">
            <label class="col-12 col-lg-4">{{ $t('adminUser.group') }}</label>
            <div class="col-12 col-lg-8">
              <multiselect
                name="group"
                id="groupField"
                v-model="group"
                :options="groups"
                :searchable="true"
                :close-on-select="true"
                track-by="id"
                label="name"
              />
              <span class="val-error">{{ validation.firstError('group') }}</span>
            </div>
          </div>
          <div class="d-flex align-center justify-center mt-4">
            <v-btn color="primary" class="bottom--button" type="submit">
              {{ $t('adminUser.add') }}
            </v-btn>
          </div>
        </form>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');
import LodashMixin from '@/mixins/lodash';

export default {
  name: 'modal-admin',
  mixins: [HelperMixin, LodashMixin],
  components: { Multiselect },
  data() {
    return {
      modalName: 'modal-admin',
      commitString: null,
      dispatchString: null,
      dispatchAfterString: null,
    };
  },
  computed: {
    ...mapState({
      groups: (state) => state.adminUser.form.groups,
    }),
    name: {
      get() {
        return this.$store.state.adminUser.form.name;
      },
      set(value) {
        this.$store.commit('adminUser/form/SET_NAME', value);
      },
    },
    email: {
      get() {
        return this.$store.state.adminUser.form.email;
      },
      set(value) {
        this.$store.commit('adminUser/form/SET_EMAIL', value);
      },
    },
    password: {
      get() {
        return this.$store.state.adminUser.form.password;
      },
      set(value) {
        this.$store.commit('adminUser/form/SET_PASSWORD', value);
      },
    },
    group: {
      get() {
        return this.$store.state.adminUser.form.group;
      },
      set(value) {
        this.$store.commit('adminUser/form/SET_GROUP', value);
      },
    },
  },
  validators: {
    name(value) {
      return Validator.value(value).required(this.$i18n.t('errors.adminUser.name.required'));
    },
    email(value) {
      return Validator.value(value).required(this.$i18n.t('errors.adminUser.email.required'));
    },
    password(value) {
      return Validator.value(value).required(this.$i18n.t('errors.adminUser.password.required'));
    },
    group(value) {
      return Validator.value(value).required(this.$i18n.t('errors.adminUser.group.required'));
    },
  },
  methods: {
    beforeOpen(event) {
      this.commitString = event.params.commitString;
      this.dispatchString = event.params.dispatchString;
      this.dispatchAfterString = event.params.dispatchAfterString;
      this.$store.commit('adminUser/form/SET_FORM_TYPE', event.params.formType);
    },
    close() {
      this.$modal.hide(this.modalName);
    },
    async submit() {
      if (await this.$validate()) {
        if (this.commitString) {
          this.$store.commit(this.commitString, this.$store.getters['adminUser/form/formData']);
        }
        await this.$store.dispatch(this.dispatchString);
        if (this.dispatchAfterString) {
          await this.$store.dispatch(this.dispatchAfterString);
        }
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('adminUser.successMsg'),
          'success',
        );
        this.close();
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
  },
};
</script>

<style scoped></style>
